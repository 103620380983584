import React, { useState } from "react"
import ContactForm from "../components/ContactForm"
import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"
import Profile from "../components/Profile"

const FORM_STATUS = {
  NONE: "NONE",
  SUCCESS: "SUCCESS",
  FAIL: "FAIL",
  RECAPTCHA_FAILURE: "RECAPTCHA_FAILURE",
}
const IndexPage = () => {
  const [formStatus, setFormStatus] = useState("NONE")

  return (
    <Layout>
      <SiteMetadata
        title="Contact - Nick Partridge"
        description="Get in touch with me."
      />
      <div className="md:flex">
        <aside className="p-6 md:p-8 main__aside">
          <Profile />
        </aside>
        <div className="main__main md:px-12 p-6 md:py-8">
          {formStatus === FORM_STATUS.NONE && (
            <>
              <p className="py-8">
                Want to get in touch with me? Fill out the form below, or flick
                me an email at{" "}
                <span className="font-bold">
                  nick(.)w(.)partridge(@)gmail(.)com
                </span>
              </p>
              <ContactForm
                formStatus={formStatus}
                onSetFormStatus={setFormStatus}
              />{" "}
            </>
          )}
          <div className="mt-3">
            {formStatus === FORM_STATUS.SUCCESS && (
              <p className="text-green-500 font-bold">
                Message sucessfully sent!
              </p>
            )}
            {formStatus === FORM_STATUS.RECAPTCHA_FAILURE && (
              <p className="text-red-500 font-bold">
                Could not verify that you are human, please try again later.
              </p>
            )}
            {formStatus === FORM_STATUS.FAIL && (
              <p className="text-red-500 font-bold">
                Message could not be sent, if you want to contact me anyways
                please{" "}
                <a
                  href="mailto: nick.w.partridge@gmail.com"
                  className="text-blue-700"
                >
                  click here
                </a>
                .
              </p>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage

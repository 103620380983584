import React, { useState } from "react"
import { Formik, Form, Field } from "formik"
import * as Yup from "yup"
import qs from "qs"
import axios from "axios"

import ReCAPTCHA from "react-google-recaptcha"
import Spinner from "../components/Spinner"

const FORM_STATUS = {
  NONE: "NONE",
  SUCCESS: "SUCCESS",
  FAIL: "FAIL",
  RECAPTCHA_FAILURE: "RECAPTCHA_FAILURE",
}

const ContactForm = ({ onSetFormStatus }) => {
  const [loading, setLoading] = useState(false)

  const recaptchaRef = React.useRef()

  const contactValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email needs to be an email address")
      .required("Email is required"),
    subject: Yup.string().required("Subject is required"),
    message: Yup.string().required("Message is required"),
  })

  const onExpire = () => {
    resetReCaptcha()
  }

  const resetReCaptcha = async () => {
    await recaptchaRef.current.reset()
  }

  const handleSubmit = async (data, { resetForm }) => {
    let captchaTokenV3
    try {
      captchaTokenV3 = await recaptchaRef.current.executeAsync()
    } catch (error) {
      return onSetFormStatus(FORM_STATUS.RECAPTCHA_FAILURE)
    }

    try {
      const payload = {
        method: "POST",
        url: "/",
        data: qs.stringify({ "g-recaptcha-response": captchaTokenV3, ...data }),
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      }

      await axios(payload)
      onSetFormStatus(FORM_STATUS.SUCCESS)
    } catch (err) {
      onSetFormStatus(FORM_STATUS.FAIL)
    } finally {
      resetForm({ values: "" })
      setLoading(false)
    }
  }

  return (
    <Formik
      initialValues={{
        email: "",
        subject: "",
        message: "",
        "bot-field": "",
        "form-name": "contact",
      }}
      validationSchema={contactValidationSchema}
      onSubmit={handleSubmit}
    >
      <Form
        data-netlify="true"
        data-netlify-recaptcha="true"
        data-netlify-honeypot="bot-field"
        name="contact"
      >
        <Field name="email">
          {({ field, form }) => (
            <div className="mb-3">
              <input
                className={`form-input ${
                  form.errors.email && form.touched.email
                    ? "border-red-400"
                    : ""
                }`}
                placeholder="Your e-mail address"
                type="email"
                required
                {...field}
              />
              {form.errors.email && form.touched.email && (
                <span className="text-red-500">{form.errors.email}</span>
              )}
            </div>
          )}
        </Field>
        <Field name="subject">
          {({ field, form }) => (
            <div className="mb-3">
              <input
                placeholder="Subject"
                className={`form-input ${
                  form.errors.subject && form.touched.subject
                    ? "border-red-400"
                    : ""
                }`}
                type="text"
                required
                {...field}
              />
              {form.errors.subject && form.touched.subject && (
                <span className="text-red-500">{form.errors.subject}</span>
              )}
            </div>
          )}
        </Field>
        <Field name="message" type="textarea">
          {({ field, form }) => (
            <div className="mb-3">
              <textarea
                placeholder="Your message..."
                className={`form-input ${
                  form.errors.message && form.touched.message
                    ? "border-red-400"
                    : ""
                }`}
                type="text"
                rows="6"
                required
                {...field}
              ></textarea>
              {form.errors.message && form.touched.message && (
                <span className="text-red-500">{form.errors.message}</span>
              )}
            </div>
          )}
        </Field>

        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey={process.env.GATSBY_RECATPCHA_KEY}
          onExpired={onExpire}
          onError={() => onSetFormStatus(FORM_STATUS.FAIL)}
        />

        <Field type="hidden" name="bot-field" />
        <Field type="hidden" name="form-name" />

        <button
          type="submit"
          className="bold py-2 px-6 bg-black text-white rounded shadow hover:opacity-75 transition-opacity duration-200 ease-in-out md:w-56 w-full disabled:opacity-50 disabled:cursor-default"
          disabled={loading}
        >
          {loading ? <Spinner /> : "Send Message"}
        </button>
      </Form>
    </Formik>
  )
}

export default ContactForm
